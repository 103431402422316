






































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceStoreModule from "@/store/modules/service";
import productStoreModule from "@/store/modules/productInventory";
import membershipStoreModule from "@/store/modules/membership";
import {
  Business,
  Membership,
  Role,
  Service,
  File as _File,
  Product,
} from "@/types";

const { mapActions: membershipActions } = createNamespacedHelpers("MEMBERSHIP");
const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_LIST");

const { mapActions: productActions, mapGetters: productGetters } =
  createNamespacedHelpers("PRODUCT_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField0: HTMLInputElement;
        imageField1: HTMLInputElement;
        imageField2: HTMLInputElement;
        imageField3: HTMLInputElement;
        policyField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "EditMembershipForm",
  props: {
    membershipId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currency: "KES",
    images: [] as _File[],
    name: "",
    description: "",
    priceRules: [(v: string) => !!v || "Price field is required"],
    nameRules: [(v: string) => !!v || "Name field is required"],
    descriptionRules: [],
    policy: undefined as undefined | File,
    addServiceDialog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      // { text: "Duration", value: "durationInMinutes" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    selectedServiceIds: [] as string[],
    selectedServices: [] as Service[],
    price: {
      weekly: 0,
      fortnightly: 0,
      monthly: 0,
      annually: 0,
      biannually: 0,
      lumpsum: 0,
    },
    weekly: false,
    fortnightly: false,
    monthly: false,
    annually: false,
    biannually: false,
    lumpsum: false,
    cancellationPolicyText: "",
    newImages: [] as File[],
    showWeeklyLimit: false,
    weeklyLimit: null as null | number,
    showMonthlyLimit: false,
    monthlyLimit: null as null | number,
    showYearlyLimit: false,
    yearlyLimit: null as null | number,
    selectedProductIds: [] as string[],
    selectedProducts: [] as Product[],
    addProductDialog: false,
    durations: ["Weeks", "Months", "Years"],
    period: 1,
    duration: "Weeks",
    durationRules: [(v: string) => !!v || "This field is required"],
    minPeriod: 1,
    maxPeriod: 4,
  }),
  watch: {
    role: "fetchServices",
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
    membership() {
      if (this.membership) {
        const ids: string[] = [];
        for (let i = 0; i < this.membership.services.length; i++) {
          ids.push((this.membership.services[i] as Service)._id);
        }
        this.selectedServiceIds = ids;
        this.selectedProductIds = this.membership.products.map(
          (item) => item._id
        );
      }
    },
    duration() {
      if (this.duration === "Weeks") {
        this.minPeriod = 1;
        this.maxPeriod = 4;
      } else if (this.duration === "Months") {
        this.minPeriod = 1;
        this.maxPeriod = 12;
      } else if (this.duration === "Years") {
        this.minPeriod = 1;
        this.maxPeriod = 99;
      }
    },
  },
  created() {
    this.fetchServices();
    this.resetForm();
  },
  computed: {
    ...serviceGetters(["servicePage"]),
    ...productGetters(["productPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    membership(): Membership {
      return this.$store.getters["MEMBERSHIP/getMembership"](this.membershipId);
    },
    periodRules() {
      return [
        (v: number) => !!v || "Period field is required",
        (v: number) =>
          v <= this.maxPeriod ||
          `Period should not exceed ${this.maxPeriod} ${this.duration}`,
        (v: number) =>
          v >= this.minPeriod ||
          `Period should not be below ${this.minPeriod} ${this.duration}`,
      ];
    },
  },
  methods: {
    ...membershipActions([
      "updateMembership",
      "uploadMembershipImage",
      "fetchMembership",
    ]),
    ...serviceActions(["fetchServiceList"]),
    ...productActions(["fetchProductList"]),
    validateMembership() {
      const valid = (
        this.$refs.membershipForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const usageLimits: Membership["usageLimits"] = [];

      if (this.showWeeklyLimit && this.weeklyLimit !== null) {
        usageLimits.push({
          limit: this.weeklyLimit,
          period: "week",
        });
      }

      if (this.showMonthlyLimit && this.monthlyLimit !== null) {
        usageLimits.push({
          limit: this.monthlyLimit,
          period: "month",
        });
      }

      if (this.showYearlyLimit && this.yearlyLimit !== null) {
        usageLimits.push({
          limit: this.yearlyLimit,
          period: "year",
        });
      }

      const payload = {
        name: this.name,
        description: this.description,
        businessId: (this.role.business as Business)._id,
        price: this.price,
        duration: {
          duration: this.period,
          duration_type: this.duration.toLowerCase(),
        },
        serviceIds: this.selectedServiceIds,
        productIds: this.selectedProductIds,
        cancellationPolicyText: this.cancellationPolicyText,
        images: this.images.map((i) => {
          delete i.id;
          return i;
        }),
        usageLimits: usageLimits.length ? usageLimits : undefined,
      };

      if (!payload.usageLimits) delete payload.usageLimits;

      this.updateMembership({
        id: this.membershipId,
        membership: payload,
      }).then((membership) => {
        if (membership) {
          let p1, p2;
          if (this.policy) {
            const data = new FormData();
            data.append("file", this.policy as File);
            p1 = this.updateMembership({
              id: `${membership._id}/cancellation-policy`,
              membership: data,
            });
          }

          if (this.newImages.length) {
            const formData = new FormData();
            this.newImages.map((i) => {
              if (i) formData.append("files", i);
            });

            p2 = this.uploadMembershipImage({
              id: membership._id,
              membership: formData,
            });
          }

          Promise.all([p1, p2]).then((ep) => {
            if (ep) this.$router.push("/membership");
          });
        }
      });
    },
    upload(files: FileList, index: number) {
      this.$set(this.newImages, index, files[0]);
    },
    fetchServices() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchServiceList(params);
        this.fetchProductList(params);
      }
    },
    selectService() {
      this.selectedServices = this.servicePage.docs.filter((s: Service) =>
        this.selectedServiceIds.includes(s._id)
      );
    },
    removeService(service: Service) {
      let indx = -1;
      this.selectedServices.map((s, i) => {
        if (s._id === service._id) indx = i;
      });

      this.selectedServices.splice(indx, 1);
    },
    selectProduct() {
      this.selectedProducts = this.productPage.docs.filter((s: Product) =>
        this.selectedProductIds.includes(s._id)
      );
    },
    removeProduct(product: Product) {
      let indx = -1;
      this.selectedProducts.map((s, i) => {
        if (s._id === product._id) indx = i;
      });

      this.selectedProducts.splice(indx, 1);
    },
    resetForm() {
      this.fetchMembership(`?membershipId=${this.membershipId}`).then(
        (membership) => {
          if (membership) {
            this.name = this.membership.name;
            this.description = this.membership.description;
            this.price = this.membership.price;
            this.period = this.membership.duration.duration;
            this.duration = capitalizeFirstLetter(
              this.membership.duration.duration_type
            );
            // this.policy = this.membership.cancellationPolicy as File;
            this.selectedServices = this.membership.services as Service[];
            this.selectedProducts = this.membership.products as Product[];
            if (this.membership.price.weekly) this.weekly = true;
            if (this.membership.price.fortnightly) this.fortnightly = true;
            if (this.membership.price.monthly) this.monthly = true;
            if (this.membership.price.annually) this.annually = true;
            if (this.membership.price.biannually) this.biannually = true;
            if (this.membership.price.lumpsum) this.lumpsum = true;

            this.membership.usageLimits?.map((ul) => {
              if (ul.period === "year") {
                this.yearlyLimit = ul.limit;
                this.showYearlyLimit = true;
              }
              if (ul.period === "month") {
                this.monthlyLimit = ul.limit;
                this.showMonthlyLimit = true;
              }
              if (ul.period === "week") {
                this.weeklyLimit = ul.limit;
                this.showWeeklyLimit = true;
              }
            });

            this.cancellationPolicyText =
              this.membership.cancellationPolicyText;
            this.images = this.membership.images as _File[];
            this.newImages = [];
          }
        }
      );
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
    getUrl(image: _File) {
      return image ? `url(${this.apiUrl}/v1/file/${image.filename})` : "";
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SERVICE_LIST")) {
      this.$store.registerModule("SERVICE_LIST", serviceStoreModule);
    }
    if (!this.$store.hasModule("PRODUCT_LIST")) {
      this.$store.registerModule("PRODUCT_LIST", productStoreModule);
    }
    if (!this.$store.hasModule("MEMBERSHIP")) {
      this.$store.registerModule("MEMBERSHIP", membershipStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_LIST");
    this.$store.unregisterModule("MEMBERSHIP");
  },
});
